import { StyleSheet,Platform,Dimensions } from 'react-native';
const windowWidth = Dimensions.get('window').width;

const a=Platform.OS=='web' ? (windowWidth/(windowWidth<900 ? 2 : 4))  : (windowWidth/1.6) 

const styles = StyleSheet.create({
  bgColor:{
    flex:1,
    height:100
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
 
  image: {
    height:150,
    width:250,
    resizeMode:'contain'
  },
 
  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: a,
    height: 45,
    marginBottom: 20,
    justifyContent:'center',
 
    alignItems: "center",
  },
  loginText:{ color:'#fff',fontSize:18 },
  TextInput: {
    height: 50,
    width:'90%',
    flex: 1,
  },
  forgot_button: {
    height: 30,
    marginBottom: 30,
    color:'#fff'
  },
 
  loginBtn: {
    width: a,
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#34ae26",
  },
  register:{
    marginTop:30
  },
  registerText:{
    color:'#fff',
    fontWeight:'bold',
  },
  errorContainer:{
    width:a,
    backgroundColor:'rgba(186,17,47,.6)',
    padding:10,
    borderRadius:40
  },
  errorText:{
    color:'#fff'
  }
});
export default styles