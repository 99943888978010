import React,{useState} from 'react'
import { View, Text } from 'react-native'

import {createStackNavigator} from 'react-navigation-stack'
import {createAppContainer} from 'react-navigation'


import Router from './src/Router'
import Register from './src/screens/Register'
import Forgot from './src/screens/Forgot'
import Login from './src/screens/Login'
const App=createStackNavigator(
  {
    Home:{screen:Router},
    Login:{screen:Login},
    Register:{screen:Register},
    Forgot:{screen:Forgot},
  },
  { 
    initialRouteName:"Login",
    headerLeft:null,
    headerMode:null
  }
)
export default createAppContainer(App)