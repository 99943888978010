import { StyleSheet,Platform,Dimensions } from 'react-native';
const windowWidth = Dimensions.get('window').width;

const a=Platform.OS=='web' ? (windowWidth/(windowWidth<900 ? 2 : 4))  : (windowWidth/1.6) 

const cursor=Platform.OS=='web' ? {cursor:'pointer'} : ''

const styles = StyleSheet.create({
  draweritems:{ 

  },
  drawerContent: {
    flex: 1,
    marginTop:30
    
  },
  image: {
    flex: 1,
    width:'80%',
    marginLeft:'10%',
    height:50,
    resizeMode: "cover",
    justifyContent: "center"
  },
  userInfoSection: {
      margin:0,
      paddingTop:80,
    paddingLeft: 20,
    alignItems:'center'
   
  },
  title: {
    color:'#fff',
    fontWeight: "bold",
  },
  caption: {
    color:'#fff',
    fontSize: 14,
    lineHeight: 14,
    paddingBottom:20,
  },
  row: {
   
    marginBottom:10,
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  paragraph: {
    fontWeight: "bold",
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 15,
  },
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});
export default styles