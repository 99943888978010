import { 
  APP_VERSION,
  API_URL,
  APP_LOGIN_IMAGE,
  APP_IMAGE_BLUE,
  API_LOGIN,
  APP_LOGIN_IMAGE_TEXT,
  APP_LOGIN_TEXT_IMAGE,
  API_REGISTER,PROXY,
  API_SEND_FORGOT_CODE,
  API_NEW_PASSWORD,
  API_GET_MESSAGES,
  API_SEND_MESSAGE,
  API_RESPONSE_MESAGE
 } from "@env";

export default {
  APP_VERSION,
  API_URL,
  APP_LOGIN_IMAGE,
  APP_IMAGE_BLUE,
  API_LOGIN,
  APP_LOGIN_IMAGE_TEXT,
  APP_LOGIN_TEXT_IMAGE,
  API_REGISTER,PROXY,
  API_SEND_FORGOT_CODE,
  API_NEW_PASSWORD,
  API_GET_MESSAGES,
  API_SEND_MESSAGE,
  API_RESPONSE_MESAGE
}