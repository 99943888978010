import { StyleSheet,Platform,Dimensions } from 'react-native';
const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  header:{
    backgroundColor:'#74b9ff',
    height:Platform.OS=='ios' ? 90 : (Platform.OS=='web' ? 50 : 70),
    alignItems:'center',
    justifyContent:'space-around',
    flexDirection:'row',paddingTop: Platform.OS === "android" ? 10 : 0,
    zIndex:10
  },
  menu:{
    bottom:Platform.OS=='android' ? -5 : (Platform.OS=='web' ? -2 : 5)
  },
  logo:{
    width:windowWidth/2
  },
  image:{
    resizeMode:'contain',
    height:Platform.OS=='ios' ? 90 : (Platform.OS=='web' ? 30 : 70),
    width:windowWidth/3
  }
});


export default styles