import { StyleSheet,Platform,Dimensions } from 'react-native';
const windowHeight = Dimensions.get('window').height;
const styles=StyleSheet.create({
  flexer:{flex:1},
  chatScreen:{
    flex:1,
    backgroundColor:'#eee',
    position:'relative',
    zIndex:5
    
  },
  sendContainer:{
    flexDirection:'row',
    backgroundColor:'#ddd',
    height:50,
    width:'100%',
    position:Platform.OS!='web' ? 'absolute' : 'fixed',
    bottom:0
  },
  message:{
    width:Platform.OS=='web' ? '95%' : '80%',
    padding:15,
  },
  sendBtn:{
    width:Platform.OS=='web' ? '5%' : '20%',
    justifyContent:'center',
    alignItems:'center'
  },
  messages:{
    marginBottom:60,
    height:40,
    flex:undefined
  },  
  sender:{
    backgroundColor:'#ced6e0',
    width:'80%',
    left:'3%',
    borderRadius:8,
    marginTop:10,
    padding:10,
    borderTopLeftRadius:0
  },
  send:{
    backgroundColor:'#a6e7e9',
    width:'80%',
    marginLeft:'17%',
    borderRadius:8,
    marginTop:10,
    padding:10,
    borderTopRightRadius:0
  },
  messageContent:{
    paddingBottom:5,
  },
  messageContentTextSend:{
    fontSize:12
  },
  messageContentText:{
    fontSize:12,paddingTop:5
  },
  messageName:{
  },
  messageNameText:{
    fontWeight: 'bold',
    fontSize:13
  },
  messageDate:{
    position:'absolute',
    right:5,bottom:5
  },
  messageDateText:{
    fontSize:10
  },
})


export default styles
