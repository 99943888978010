import { StatusBar } from "expo-status-bar";
import React, { useState,useLayoutEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { ALERT_TYPE, Dialog, Root, Toast } from 'react-native-alert-notification';
import AsyncStorage from "@react-native-async-storage/async-storage";

import axios from 'axios'
import config from "../../config";

import styles from '../components/styles/Auth'

export default class Login extends React.Component {
  constructor(){
    super()
    this.state={
      username:"",
      password:"",
      see:true,
      error:null,
      post:false,
      loginUrl:config.API_LOGIN,
      proxyUrl:config.PROXY
    }
    
  }
  
  componentDidMount(){
    /*  OTOMATİK GİRİŞ İÇİN İŞLEM TEST SONRASI KOMUT SATIRINDAN ÇIKARILACAK */
    AsyncStorage.multiGet(['username','password']).then((response)=>{
      if(response[0][1]!=null && response[1][1]!=null){
        this.setState({username:response[0][1],password:response[1][1]})
        this.loginIn()
      }
    })
     
  }

  loginIn=async ()=>{
    this.setState({post:true})

    const data={
      username:this.state.username,
      password:this.state.password
    }
   
    const loginUrl= await (this.state.proxyUrl+"/"+this.state.loginUrl)
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Target-URL' : this.state.loginUrl,
        "X-Requested-With": "XMLHttpRequest"
      }
    }
    axios.post(loginUrl,data,config)      
      .then((response) => {
        if(response.data.error==false){
          
          this.setState({error:null})
          AsyncStorage.multiSet([
                          ['chatEmail',response.data.email],
                          ['chatToken',response.data.token],
                          ['chatId',response.data.id],
                          ['username',this.state.username],
                          ['password',this.state.password]
                        ])
          this.props.navigation.navigate('Home')
        }else{
          this.setState({error:response.data.msg})
        }
        this.setState({post:false})
      })
      .catch((error) => {
        this.setState({error:error,post:false})
      })
  }
  render(){
    return (
      <LinearGradient
        start={[0, 0]}
        end={[1, 1]}
        location={[0.25, 0.4, 1]}
        colors={['#fff', '#3b5998', '#192f6a']}
        style={styles.bgColor}
       >
    <View style={styles.container}>
      <Image style={styles.image} source={{uri:config.APP_LOGIN_IMAGE}} />
 
      <StatusBar style="auto" />
      
      

     
      {
        this.state.post ? 
        <View>
          <ActivityIndicator size="large" color="#fff" />
        </View>
        : 
        <>

          <View style={styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="Kullanıcı adı"
              placeholderTextColor="#003f5c"
              onSubmitEditing={ () => this.loginIn() }
              onChangeText={(username) => this.setState({username:username})}
            />
          </View>
    
          <View style={styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="Parola"
              placeholderTextColor="#003f5c"
              secureTextEntry={this.state.see}
              onSubmitEditing={ () => this.loginIn() }
              onChangeText={(password) => this.setState({password:password})}
            />
            <Ionicons onPress={()=>{if(this.state.see==true){ this.setState({see:false}) }else{this.setState({see:true})}}} style={{position:'absolute',right:15}} name={this.state.see ? "eye" : "eye-off"} size={22}  /> 
          </View>
    
          <TouchableOpacity
          
          onPress={()=>{
            this.props.navigation.navigate('Forgot')
          }}
          >
            <Text style={styles.forgot_button}>Şifremi unuttum?</Text>
          </TouchableOpacity>
           {
            this.state.error!=null ? 
            <View style={styles.errorContainer}>
              <Text style={styles.errorText}> {this.state.error} </Text>
            </View>
            : <></>
          }
          <TouchableOpacity
            disabled={this.state.post}
            onPress={ () => this.loginIn()}
            style={styles.loginBtn}>
              <Text style={styles.loginText}>Giriş Yap</Text>
          </TouchableOpacity>
          <TouchableOpacity  
            onPress={()=>{
              this.props.navigation.navigate('Register')
            }}
            style={styles.register}>
              <Text style={styles.registerText}>Hesap Oluştur</Text>
            </TouchableOpacity>
         </>
      }
       
        


      
      
    </View>
      </LinearGradient>
  );
  }
}
 
