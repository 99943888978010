import React, { Component } from 'react'
import { Text,ActivityIndicator, View,StyleSheet,TextInput,TouchableOpacity,Platform,ScrollView,FlatList } from 'react-native'

import Header from '../components/Header/Header'
import { MaterialIcons } from '@expo/vector-icons'; 

import styles from '../components/styles/Chat'
import Sender from '../components/Message/Sender'
import Sending from '../components/Message/Sending'
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../../config';
import axios from 'axios'

import Pusher from 'pusher-js/react-native';


export default class Home extends Component {
  constructor(props){
    super(props)
    this.state={
      message:"",
      username:undefined,
      password:undefined,
      token:undefined,
      email:undefined,
      messageUrl:config.API_GET_MESSAGES,
      sendUrl:config.API_SEND_MESSAGE,
      detailUrl:config.API_RESPONSE_MESAGE,
      version:config.APP_VERSION,
      proxyUrl:config.PROXY,
      counter:0,
      refreshing:false,
      messages:null
      
    }

    var pusher = new Pusher('cc6a096a59440665729b', {
      cluster: 'eu'
    });

    var channel = pusher.subscribe('my-channel');
    channel.bind('my-event',(data)=> this.getMessageInfo(data));
    this.getMessages()
  }
  getMessageInfo= async (data)=>{
    const id=await ((JSON.parse(JSON.stringify(data))).messageId)

    const request={
      token:this.state.token,
      version:this.state.version,
      msg:id
    }
    
    const detailUrl= await (this.state.proxyUrl+"/"+this.state.detailUrl)
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Target-URL' : this.state.detailUrl,
        "X-Requested-With": "XMLHttpRequest"
      }
    }
    axios.post(detailUrl,request,config)      
      .then((response) => {
        if(response.data.profile==false){
          this.setState({messages:[...this.state.messages,{username:response.data.username,profile:response.data.profile,message:response.data.message,id:response.data.id,date:response.data.date}],refreshing:true})
        }
      })
      .catch((error) => {
        
      })
      this.setState({refreshing:false})
  }
  
  getUserInfo=async()=>{
    const data=await AsyncStorage.multiGet(['chatToken','username','password','chatEmail'])
    const waiter=await this.setState({
      username:data[1][1],
      password:data[2][1],
      email:data[3][1],
      token:data[0][1],
    })
  }
  getMessages=async()=>{
    const settEr=await this.getUserInfo() 
    const request={
      username:this.state.username,
      password:this.state.password,
      email:this.state.email,
      token:this.state.token,
      version:this.state.version,
      type:'loading',
    }
    
    const messageUrl= await (this.state.proxyUrl+"/"+this.state.messageUrl)
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Target-URL' : this.state.messageUrl,
        "X-Requested-With": "XMLHttpRequest"
      }
    }
    axios.post(messageUrl,request,config)      
      .then((response) => {
        console.log(response)
        if(response.data.login==true){
          this.setState({messages:response.data.messages})
        }
      })
      .catch((error) => {
        
      })
  }
  
  
  sendMessage=async()=>{
    if((this.state.message.replace(" ","")).length>1){
      const current_date=await new Date();
      const hour=await ('0'+current_date.getHours()).slice(-2)+':'+('0'+current_date.getMinutes()).slice(-2);
      const count=await this.setState({counter:this.state.counter+1})
      const set = await this.setState({messages:[...this.state.messages,{profile:true,message:this.state.message,id:'locale'+this.state.counter,date:hour}],refreshing:true})
      
      const request=await {
        username:this.state.username,
        password:this.state.password,
        email:this.state.email,
        token:this.state.token,
        version:this.state.version,
        message:this.state.message
      }
      
      const sendUrl= await (this.state.proxyUrl+"/"+this.state.sendUrl)
      let config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Target-URL' : this.state.sendUrl,
          "X-Requested-With": "XMLHttpRequest"
        }
      }
      axios.post(sendUrl,request,config)      
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          
        })
    
      const last = await this.setState({refreshing:false,message:''})
    }
  }
 
  render() {
    return (
      <View style={styles.flexer}>
        <Header style={styles.flexer} openDrawer={this.props.navigation} />

        <View style={styles.chatScreen}>

          <ScrollView 
          ref={ref => this.scrollView = ref}
          onContentSizeChange={(contentWidth, contentHeight)=>{        
              this.scrollView.scrollToEnd({animated: true});
          }}
          style={styles.messages} >
            {
             this.state.message!=null ? 
              <FlatList 
              style={styles.flatlist}
              data={this.state.messages}
              keyExtractor={(item)=> 'key'+item.id}
              refreshing={this.state.refreshing}
              renderItem={({item})=>{
                return(
                  <>
                  {item.profile==true ? <Sending data={item} />  : <Sender data={item} /> }
                  </>
                )
              
              }}
              />
              :
              <View  style={{flex:1,height:400,marginTop:Platform.OS=='web' ? 180 : 0,justifyContent:'center',alignItems:'center'}}>
                <ActivityIndicator size="large" color="#fff" />
              </View>
            }
            {/* <View style={styles.sender}>
              <View style={styles.messageName}>
                <Text style={styles.messageNameText}>Mücahit Sendinç</Text>
              </View>
              
              <View style={styles.messageContent}>
                <Text  style={styles.messageContentText}>Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri standardı sahte metinler olarak kullanılmıştır. Beşyüz yıl boyunca varlığını sürdürmekle kalmamış, aynı zamanda pek değişmeden elektronik dizgiye de sıçramıştır. 1960'larda Lorem Ipsum pasajları da içeren Letraset yapraklarının yayınlanması ile ve yakın zamanda Aldus PageMaker gibi Lorem Ipsum sürümleri içeren masaüstü yayıncılık yazılımları ile popüler olmuştur.</Text>
              </View>

              <View  style={styles.messageDate}>
                <Text style={styles.messageDateText}>15:13</Text>
              </View>

            </View>

            <View style={styles.send}>
              <View style={styles.messageContent}>
                <Text  style={styles.messageContentTextSend}>Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri standardı sahte metinler olarak kullanılmıştır. Beşyüz yıl boyunca varlığını sürdürmekle kalmamış, aynı zamanda pek değişmeden elektronik dizgiye de sıçramıştır. 1960'larda Lorem Ipsum pasajları da içeren Letraset yapraklarının yayınlanması ile ve yakın zamanda Aldus PageMaker gibi Lorem Ipsum sürümleri içeren masaüstü yayıncılık yazılımları ile popüler olmuştur.</Text>
              </View>

              <View  style={styles.messageDate}>
                <Text style={styles.messageDateText}>15:13</Text>
              </View>

            </View> */}


          </ScrollView>

          <View style={styles.sendContainer}>
            
            <TextInput
                style={styles.message}
                multiline={true}
                numberOfLines={3}
                placeholder="Mesaj"
                placeholderTextColor="#003f5c"
                onFocus={()=>this.scrollView.scrollToEnd({animated: true})}
                onChangeText={(message) => this.setState({message:message})}
                value={this.state.message}/>

             <TouchableOpacity
                style={styles.sendBtn}
                disabled={false}
                onPress={ () => this.sendMessage()}
                >
                  <MaterialIcons style={styles.sendIcon} name="send" size={24} color="black" />
              </TouchableOpacity>


          </View>

         

        </View>

      </View>
    )
  }
}

