import React,{useState,useEffect} from "react";
import {ViewPropTypes,Text } from 'react-native'
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer,useNavigation  } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { DrawerContent } from "./components/Header/DrawerContent";
import AsyncStorage from "@react-native-async-storage/async-storage";

import Home from "./screens/Home";
import Chat from "./screens/Chat";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

function drawerStack() {
  return (
    <Stack.Navigator initialRouteName="Home" headerMode="none" headerLeft="none" >
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="Chat" component={Chat} />
    </Stack.Navigator>
  );
}

export default function DrawerNavigator(props) {
  const [logout,setLogout]=useState(false)
  useEffect(() => {
    if(logout==true){
      AsyncStorage.multiRemove([
        'token',
        'email',
        'chatEmail',
        'username',
        'password',
        'chatId',
        'chatToken',
        'chatEmail',
        'bearer',
        'code',
        'name',
        'surname'
      ])
      props.navigation.navigate('Login')
    }    
  }, [logout])
  return (
    <NavigationContainer>
      <Drawer.Navigator drawerContent={(props) => <DrawerContent cikis={logout} setCikis={setLogout} {...props} />}>
        <Drawer.Screen options={{headerShown: false}} name="Home"    component={drawerStack} headerMode="none" />
      </Drawer.Navigator> 
    </NavigationContainer>
  );
}