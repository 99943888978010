import React from 'react'
import { View, Text } from 'react-native'
import styles from '../styles/Chat'

export default function Sender(props) {
  return (
    <View style={styles.sender}>
      <View style={styles.messageName}>
        <Text style={styles.messageNameText}>{props.data.username}</Text>
      </View>
      
      <View style={styles.messageContent}>
        <Text  style={styles.messageContentText}>{props.data.message}</Text>
      </View>

      <View  style={styles.messageDate}>
        <Text style={styles.messageDateText}>{props.data.date}</Text>
      </View>

    </View>
  )
}
