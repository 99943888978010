import React, { Component } from 'react'
import { Text, View,SafeAreaView } from 'react-native'

import Header from '../components/Header/Header'

export default class Home extends Component {
  constructor(props){
    super(props)
  }
  render() {
    return (
        <Header openDrawer={this.props.navigation} />
    )
  }
}
