import React from "react";
import { View, StyleSheet,Image,SafeAreaView} from "react-native";
import { DrawerItem, DrawerContentScrollView } from "@react-navigation/drawer";
import {  Drawer, } from "react-native-paper";
import config from '../../../config'
import { MaterialCommunityIcons,MaterialIcons,Entypo,Ionicons,Foundation  } from "@expo/vector-icons";

import styles from '../styles/Drawer'

export function DrawerContent(props) {
  return (
     
    <DrawerContentScrollView {...props} contentContainerStyle={{paddingTop:0,marginTop:0}}>
    
      <SafeAreaView style={styles.drawerContent}>
      <Image source={{uri:config.APP_IMAGE_BLUE}} style={styles.image} />
       
        <Drawer.Section  style={styles.drawerSection}>
          <DrawerItem
            style={styles.draweritems}
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="home-outline"
                color={color}
                size={size}
              />
            )}
            label="Ana Sayfa"
            onPress={() => { props.navigation.navigate("Home") }}
          />

          <DrawerItem
            style={styles.draweritems}
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="message-minus-outline"
                color={color}
                size={size}
              />
            )}
            label="Sohbet"
            onPress={() => { props.navigation.navigate("Chat") }}
          />
         
       
      
        </Drawer.Section>
        <Drawer.Section title="Hesap">
          <DrawerItem
            style={styles.draweritems}
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="logout"
                color={color}
                size={size}
              />
            )}
            label="Çıkış Yap"
            onPress={() => { props.setCikis(true) }}
          />
          
        </Drawer.Section>
      </SafeAreaView>
    </DrawerContentScrollView>
  );
}

