import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { ALERT_TYPE, Dialog, Root, Toast } from 'react-native-alert-notification';
import AsyncStorage from "@react-native-async-storage/async-storage";

import axios from 'axios'
import config from "../../config";

import styles from '../components/styles/Auth'


export default class Login extends React.Component {
  constructor(){
    super()
    this.state={
      username:"",
      password:"",
      email:"",
      see:true,
      error:null,
      post:false,
      registerUrl:config.API_REGISTER,
      loginUrl:config.API_LOGIN,
      proxyUrl:config.PROXY
    }
  }
  loginIn=async ()=>{

    const data={
      username:this.state.username,
      password:this.state.password
    }
   
    const loginUrl= await (this.state.proxyUrl+"/"+this.state.loginUrl)
    console.log(loginUrl)
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Target-URL' : this.state.loginUrl,
        "X-Requested-With": "XMLHttpRequest"
      }
    }
    axios.post(loginUrl,data,config)      
      .then((response) => {
        if(response.data.error==false){
            console.log(response)
          
          this.setState({error:null})
          AsyncStorage.multiSet([['chatEmail',response.data.email],['chatToken',response.data.token],['chatId',response.data.id]])
          this.props.navigation.navigate('Home')
        }else{
          this.setState({error:response.data.msg})
        }
        this.setState({post:false})
      })
      .catch((error) => {
        this.setState({error:error,post:false})
      })
  }
  registerIn=async ()=>{
    this.setState({post:true})

    const data={
      email:this.state.email,
      username:this.state.username,
      password:this.state.password
    }
   
    const registerUrl= await (this.state.proxyUrl+"/"+this.state.registerUrl)
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Target-URL' : this.state.registerUrl,
        "X-Requested-With": "XMLHttpRequest"
      }
    }
    axios.post(registerUrl,data,config)      
      .then((response) => {
        console.log(response.data)
        if(response.data.error==false){
          this.loginIn()
        }else{
          this.setState({error:response.data.msg})
          this.setState({post:false})
        }
      })
      .catch((error) => {
        this.setState({error:error,post:false})
      })
  }
  render(){
    return (
      <LinearGradient
        start={[0, 0]}
        end={[1, 1]}
        location={[0.25, 0.4, 1]}
        colors={['#fff', '#3b5998', '#192f6a']}
        style={styles.bgColor}
       >
    <View style={styles.container}>
      <Image style={styles.image} source={{uri:"https://dehasoft.com.tr/assets/img/footer.png"}} />
 
      <StatusBar style="auto" />
     
 
  
      
      {
        this.state.post ? 
        <View>
          <ActivityIndicator size="large" color="#fff" />
        </View>
        : 
        <>
        
         <View style={styles.inputView}>
          <TextInput
            style={styles.TextInput}
            placeholder="Kullanıcı adı"
            onSubmitEditing={ () => this.registerIn() }
            placeholderTextColor="#003f5c"
            onChangeText={(username) => this.setState({username:username})}
          />
        </View>
        <View style={styles.inputView}>
          <TextInput
            style={styles.TextInput}
            placeholder="E Posta"
            onSubmitEditing={ () => this.registerIn() }
            placeholderTextColor="#003f5c"
            onChangeText={(email) => this.setState({email:email})}
          />
        </View>
  
        <View style={styles.inputView}>
          <TextInput
            style={styles.TextInput}
            placeholder="Parola"
            onSubmitEditing={ () => this.registerIn() }
            placeholderTextColor="#003f5c"
            secureTextEntry={this.state.see}
            
            onChangeText={(password) => this.setState({password:password})}
          />
          <Ionicons onPress={()=>{if(this.state.see==true){ this.setState({see:false}) }else{this.setState({see:true})}}} style={{position:'absolute',right:15}} name={this.state.see ? "eye" : "eye-off"} size={22}  /> 
        </View>
        {
        this.state.error!=null ? 
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}> {this.state.error} </Text>
          </View>
          : <></>
        }
        <TouchableOpacity 
          disabled={this.state.post}
          onPress={()=>{
            this.registerIn()
          }}
          style={styles.loginBtn}>
          <Text style={styles.loginText}>Kayıt Ol</Text>
        </TouchableOpacity>

          <TouchableOpacity 
            onPress={()=>{
              this.props.navigation.navigate('Login')
            }}
            style={styles.register}>
              <Text style={styles.registerText}>Giriş Yap</Text>
            </TouchableOpacity>
        </>
        
      }
 
      

      
    </View>
      </LinearGradient>
  );
  }
}
 
