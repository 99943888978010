import React from 'react'
import { View, Text,StyleSheet,Platform,Image,Dimensions,SafeAreaView,TouchableOpacity } from 'react-native'
import { MaterialIcons,MaterialCommunityIcons } from '@expo/vector-icons'; 


 import styles from '../styles/Header'
import config from '../../../config'
import { StatusBar } from 'expo-status-bar';
const windowWidth = Dimensions.get('window').width;
export default function Header(props) {
  return (
        
        <SafeAreaView style={styles.header}>
            <StatusBar/>

            <TouchableOpacity style={styles.menu}
              onPress={()=> props.openDrawer.openDrawer() }
              >
              <MaterialIcons name="menu" size={34} style={styles.menuIcon} color="black" />

            </TouchableOpacity>

            <TouchableOpacity style={styles.menu}
              onPress={()=> props.openDrawer.navigate('Home') }
              >
              <Image source={{uri:config.APP_LOGIN_TEXT_IMAGE}}  style={styles.image} />

            </TouchableOpacity>
            
            <TouchableOpacity style={styles.menu}
              onPress={()=> props.openDrawer.navigate('Chat') }
              >
              <MaterialCommunityIcons name="message-minus-outline" size={34} style={styles.menuIcon} color="black" />
            </TouchableOpacity>
            

        </SafeAreaView>
   
  )
}

