import { StatusBar } from "expo-status-bar";
import React, { useState,useLayoutEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { ALERT_TYPE, Dialog, Root, Toast } from 'react-native-alert-notification';
import AsyncStorage from "@react-native-async-storage/async-storage";

import axios from 'axios'
import config from "../../config";

import styles from '../components/styles/Auth'

export default class Login extends React.Component {
  constructor(){
    super()
    this.state={
      email:"",
      code:"",
      password:"",
      error:null,
      see:true,
      post:false,
      forgotUrl:config.API_SEND_FORGOT_CODE,
      newPasswordUrl:config.API_NEW_PASSWORD,
      forgotStatus:0,
      forgotToken:"",
      proxyUrl:config.PROXY
    }
    
  }
  
  componentDidMount(){
    /*  OTOMATİK GİRİŞ İÇİN İŞLEM TEST SONRASI KOMUT SATIRINDAN ÇIKARILACAK
    AsyncStorage.multiGet(['username','password']).then((response)=>{
      if(response[0][1]!=null && response[1][1]!=null){
        this.setState({username:response[0][1],password:response[1][1]})
        this.loginIn()
      }
    })
    */  
  }
  
  forgotCode=async ()=>{
    this.setState({post:true})

    if(this.state.forgotStatus==0){
      const data={
        email:this.state.email,
        forgotStatus:this.state.forgotStatus
      }

      const forgotUrl= await (this.state.proxyUrl+"/"+this.state.forgotUrl)
      let config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Target-URL' : this.state.forgotUrl,
          "X-Requested-With": "XMLHttpRequest"
        }
      }
      axios.post(forgotUrl,data,config)      
        .then((response) => {
          if(response.data.error==false){

            this.setState({post:false,error:response.data.msg,forgotStatus:1,forgotToken:response.data.token})
          }else{
            this.setState({post:false,error:response.data.msg})
          }
        })
        .catch((error) => {
          this.setState({error:error,post:false})
        })
    }else{
      const data={
        email:this.state.email,
        forgotStatus:this.state.forgotStatus,
        forgotToken:this.state.forgotToken,
        securitycode:this.state.code,
        newpassword:this.state.password
      }

      const forgotUrl= await (this.state.proxyUrl+"/"+this.state.newPasswordUrl)
      let config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Target-URL' : this.state.newPasswordUrl,
          "X-Requested-With": "XMLHttpRequest"
        }
      }
      axios.post(forgotUrl,data,config)      
        .then((response) => {
          if(response.data.error==false){

            this.setState({post:false,error:null,forgotStatus:0,forgotToken:"",code:"",email:""})
            this.props.navigation.navigate('Login')
          }else{
            this.setState({post:false,error:response.data.msg})
          }
        })
        .catch((error) => {
          this.setState({error:error,post:false})
        })
    }

    
    
  }
  render(){
    return (
      <LinearGradient
        start={[0, 0]}
        end={[1, 1]}
        location={[0.25, 0.4, 1]}
        colors={['#fff', '#3b5998', '#192f6a']}
        style={styles.bgColor}
       >
    <View style={styles.container}>
      <Image style={styles.image} source={{uri:config.APP_LOGIN_IMAGE}} />
 
      <StatusBar style="auto" />
      
      

     
      {
        this.state.post ? 
        <View>
          <ActivityIndicator size="large" color="#fff" />
        </View>
        : 
        <>

          
    
          {
            this.state.forgotStatus==1 ? 
            <>
            <View style={styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="Güvenlik Kodu"
              placeholderTextColor="#003f5c"
              onSubmitEditing={ () => this.forgotCode() }
              secureTextEntry={this.state.see}
              onChangeText={(code) => this.setState({code:code})}
            />
            <Ionicons onPress={()=>{if(this.state.see==true){ this.setState({see:false}) }else{this.setState({see:true})}}} style={{position:'absolute',right:15}} name={this.state.see ? "eye" : "eye-off"} size={22}  /> 
          </View>
          <View style={styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="Yeni Parola"
              placeholderTextColor="#003f5c"
              onSubmitEditing={ () => this.forgotCode() }
              secureTextEntry={this.state.see}
              onChangeText={(password) => this.setState({password:password})}
            />
            <Ionicons onPress={()=>{if(this.state.see==true){ this.setState({see:false}) }else{this.setState({see:true})}}} style={{position:'absolute',right:15}} name={this.state.see ? "eye" : "eye-off"} size={22}  /> 
          </View>
            </>
          
          : 
          <View style={styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="E Posta"
              value={this.state.email}
              onSubmitEditing={ () => this.forgotCode() }
              placeholderTextColor="#003f5c"
              onChangeText={(email) => this.setState({email:email})}
            />
          </View>
          }
          
           {
            this.state.error!=null ? 
            <View style={styles.errorContainer}>
              <Text style={styles.errorText}> {this.state.error} </Text>
            </View>
            : <></>
          }
          <TouchableOpacity
            disabled={this.state.post}
            onPress={ () => this.forgotCode()}
            style={styles.loginBtn}>
              <Text style={styles.loginText}>{ this.state.forgotStatus==0 ? 'Doğrulama Kodu Gönder' : 'Şifremi Güncelle' }</Text>
          </TouchableOpacity>
          <TouchableOpacity  
            onPress={()=>{
              this.props.navigation.navigate('Login')
            }}
            style={styles.register}>
              <Text style={styles.registerText}>Giriş Yap</Text>
            </TouchableOpacity>
         </>
      }
       
        


      
      
    </View>
      </LinearGradient>
  );
  }
}
 
